import './App.css';
import { useState, useEffect } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

    //first get the current location
    const [ip, setIp] = useState("");
    const [secret, setSecret] = useState("");
    const [buttonVariant, setbuttonVariant] = useState("secondary");

    useEffect(() => {
        axios
        .get("https://api.ipify.org?format=json")
        .then((response) => {
            console.log(response.data);
            setIp(response.data.ip);
        })
        .catch((error) => {
            console.error(error);
        });
    }, []);

    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        
        setInputs(values => ({...values, [name]: value, ip: ip}));
        console.log(inputs);
        console.log(ip);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (secret === "hersey"){
            try {
                let response = await axios.post('https://tss.dooritech.net/api/user/save', inputs);
                // handle successful response
                console.log(response.data);
                //setButton variant
                setbuttonVariant("success");
              } catch (error) {
                // handle error response
                if (error.response) {
                  // server returned an error
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                  // display a message based on the status code or the data
                } else if (error.request) {
                  // no response from the server
                  console.log(error.request);
                  // display a message that the connection failed or timed out
                } else {
                  // other configuration error
                  console.log(error.message);
                  // display the error message or fix the configuration
                }
          }
        } 
        else{
          setbuttonVariant("danger");
        }
          
    }

    return (
      <div className="App">
        <h4  className="mb-3" style={{ fontFamily: 'pacifico', fontSize: 36 }} >Send Message</h4>
        <Form onSubmit={handleSubmit}>

          {/* <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Enter title" name="title" onChange={handleChange} />
          </Form.Group> */}

          <Form.Group className="mb-3">
              <Form.Control as="textarea" type="text" placeholder="Enter your message" name="description" onChange={handleChange} />
          </Form.Group>

          <Form.Group className="mb-3">
              <Form.Control type="password" placeholder="Auth or secret key" name="secret" onChange={(e) => setSecret(e.target.value)}/>
          </Form.Group>

          <div className="d-grid gap-2">
              <Button variant={buttonVariant} type="submit"> Submit </Button>
          </div>

        </Form> 
            
      </div>
    );
}

export default App;
